<template>
    <el-dialog width="780px" :title="isAdd ?  '新增规格设置' : '编辑规格设置（修改类别、规格需要重新刪除）'" :visible.sync="showEdit"
               :close-on-click-modal="false">
        <el-form ref="specificationForm" :rules="specificationFormRules" :model="specificationForm"
                 label-width="100px">
            <el-row>
                <el-col :lg="12" :md="12">
                    <el-form-item label="类别名称" prop="category_name">
                        <el-select v-model="specificationForm.category_name" :disabled="isAdd ? false:true" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                   default-first-option @clear="$_searchCategoryList"
                                   @focus="$_searchCategoryList" style="width: 250px" clearable filterable>
                            <el-option v-for="item in $store.getters.getCategoryList" :key="item.id" :label="item.name"
                                       :value="item.name">
                                <span style="margin:15px;">{{ item.name}}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                </el-col>
                <el-col :lg="12" :md="12">
                    <el-form-item label="规格名称" prop="specification_name">
                        <el-input v-model="specificationForm.specification_name" :readonly="isAdd ? false:true" size="mini"
                                  style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="showEdit = false">取 消</el-button>
            <el-button v-if="isAdd ? true:false" type="primary" @click="addRoUpdateCustomerEditEvent()">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>

export default {
    name: "AddSpecification",
    props: {
        isCustomer: {//接口传参查询名称
            type: Boolean,
            default: false
       }

   },
    data() {
        return {
            showEdit: false,
            isAdd: false,
            specificationForm: {
                id: '',
                category_name : '',
                specification_name: '',
                length_extent: '',
                theoretical_weight : '',
           },
            specificationFormRules: {
                specification_name: [
                    {required: true, message: '请选输入规格名称', trigger: 'blur'}
                ],
                category_name : [
                    {required: true, message: '请选输入类别名称', trigger: 'blur'}
                ],
           },
       }
   },

    created() {

   },
    methods: {
        addEvent() {
            this.showEdit = true
            this.isAdd = true
            this.specificationForm.id = '';
            this.specificationForm.specification_name = '';
            this.specificationForm.category_name = '';
            this.specificationForm.length_extent = '';
            this.specificationForm.theoretical_weight = '';

       },
        addRoUpdateCustomerEditEvent() {
            let url = '';
            if (this.isAdd) {
                url = '/admin/specification/saveVue';
           } else {
                url = '/admin/specification/updateVue';
           }
            this.$refs.specificationForm.validate((valid) => {
                if (valid) {
                    this.$axios({
                        method: 'post',
                        url: url,
                        data: this.specificationForm,
                   }).then((response) => {          //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: '保存成功',
                                type: 'success'
                           });
                            this.showEdit = false;
                            if (this.isCustomer){
                                this.$parent.getList();
                           }
                            //this.$emit('childByAccountEvent', response.data.page.list)
                       } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) => {
                        console.log(error)
                   });
               } else {
                    //console.log('error submit!!');
                    return false;
               }
           });
       },
   }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
